import { OnDestroy, OnInit } from "@angular/core";
import { SelectionModel } from "@angular/cdk/collections";
import { ObservableMedia } from "@angular/flex-layout";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatTableDataSource } from "@angular/material";
import { AuthService, ProjectService } from "@forcrowd/backbone-client-core";
import { finalize } from "rxjs/operators";
import { ProfileRemoveProjectComponent } from "./profile-remove-project.component";
var ProfileComponent = /** @class */ (function () {
    function ProfileComponent(authService, activatedRoute, dialog, projectService, router, media) {
        this.authService = authService;
        this.activatedRoute = activatedRoute;
        this.dialog = dialog;
        this.projectService = projectService;
        this.router = router;
        this.media = media;
        this.currentUser = null;
        this.displayedColumns = ["select", "name", "ratingCount", "createdOn", "functions"];
        this.dataSource = new MatTableDataSource([]);
        this.mediaQuery = "";
        this.profileUser = null;
        this.selection = new SelectionModel(true, []);
        this.subscriptions = [];
    }
    Object.defineProperty(ProfileComponent.prototype, "isBusy", {
        get: function () {
            return this.authService.isBusy || this.projectService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ;
    ProfileComponent.prototype.confirmRemove = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ProfileRemoveProjectComponent);
        dialogRef.afterClosed().subscribe(function (confirmed) {
            if (!confirmed)
                return;
            if (_this.selection.selected.length > 0) {
                _this.selection.selected.forEach(function (project) {
                    _this.projectService.removeProject(project);
                });
                _this.selection.clear();
                _this.projectService.saveChanges().pipe(finalize(function () {
                    _this.dataSource.data = _this.profileUser.ProjectSet;
                })).subscribe();
            }
        });
    };
    /** Whether the number of selected elements matches the total number of rows. */
    ProfileComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.dataSource.data.length;
        return numSelected === numRows;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    ProfileComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    ProfileComponent.prototype.ngOnDestroy = function () {
        for (var i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    };
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUser = this.authService.currentUser;
        var profileUserName = this.activatedRoute.snapshot.params["username"] || this.currentUser.UserName;
        this.authService.getUser(profileUserName)
            .subscribe(function (user) {
            // Not found, navigate to 404
            if (user === null) {
                var url = window.location.href.replace(window.location.origin, "");
                _this.router.navigate(["/app/not-found", { url: url }]);
                return;
            }
            _this.profileUser = user;
            _this.dataSource.data = _this.profileUser.ProjectSet;
            _this.setColumns();
        });
        // Media queries
        var mediaSubscription = this.media.subscribe(function (change) {
            _this.mediaQuery = change.mqAlias;
            _this.setColumns();
        });
        this.subscriptions.push(mediaSubscription);
    };
    ProfileComponent.prototype.setColumns = function () {
        this.displayedColumns = this.currentUser === this.profileUser
            ? (this.mediaQuery !== "xs" && this.mediaQuery !== "sm")
                ? ["select", "name", "ratingCount", "createdOn", "functions"]
                : ["select", "name", "functions"]
            : (this.mediaQuery !== "xs" && this.mediaQuery !== "sm")
                ? ["name", "ratingCount", "createdOn"]
                : ["name", "createdOn"];
    };
    ProfileComponent.prototype.trackBy = function (index, item) {
        return item.Id;
    };
    return ProfileComponent;
}());
export { ProfileComponent };
