import { Routes } from "@angular/router";
// Components
import { AccountOverviewComponent } from "./account-overview.component";
import { AddPasswordComponent } from "./add-password.component";
import { ChangeEmailComponent } from "./change-email.component";
import { ChangePasswordComponent } from "./change-password.component";
import { ChangeUserNameComponent } from "./change-username.component";
import { ConfirmEmailComponent } from "./confirm-email.component";
import { LoginComponent } from "./login.component";
import { RegisterComponent } from "./register.component";
import { ResetPasswordComponent } from "./reset-password.component";
import { AuthGuard, CanDeactivateGuard } from "../core/app-core.module";
var ɵ0 = { title: "Account Overview" }, ɵ1 = { title: "Add Password" }, ɵ2 = { title: "Change Email" }, ɵ3 = { title: "Change Password" }, ɵ4 = { title: "Change Username" }, ɵ5 = { title: "Confirm Email" }, ɵ6 = { title: "Login" }, ɵ7 = { title: "Register" }, ɵ8 = { title: "Reset Password" };
// Routes
var accountRoutes = [
    { path: "app/account", component: AccountOverviewComponent, canActivate: [AuthGuard], data: ɵ0 },
    { path: "app/account/add-password", component: AddPasswordComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], data: ɵ1 },
    { path: "app/account/change-email", component: ChangeEmailComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], data: ɵ2 },
    { path: "app/account/change-password", component: ChangePasswordComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], data: ɵ3 },
    { path: "app/account/change-username", component: ChangeUserNameComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard], data: ɵ4 },
    { path: "app/account/confirm-email", component: ConfirmEmailComponent, canActivate: [AuthGuard], data: ɵ5 },
    { path: "app/account/login", component: LoginComponent, data: ɵ6 },
    { path: "app/account/register", component: RegisterComponent, data: ɵ7 },
    { path: "app/account/reset-password", component: ResetPasswordComponent, canDeactivate: [CanDeactivateGuard], data: ɵ8 }
];
var AccountModule = /** @class */ (function () {
    function AccountModule() {
    }
    return AccountModule;
}());
export { AccountModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
