import { OnInit } from "@angular/core";
import { AuthService, ProjectService } from "@forcrowd/backbone-client-core";
var GettingStartedComponent = /** @class */ (function () {
    function GettingStartedComponent(authService, projectService) {
        this.authService = authService;
        this.projectService = projectService;
        this.exampleCode = "";
        this.project = null;
        this.version = "1.0";
    }
    Object.defineProperty(GettingStartedComponent.prototype, "currentUser", {
        get: function () {
            return this.authService.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    GettingStartedComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.currentUser || !this.currentUser.isAuthenticated())
            return;
        this.authService.getUser(this.currentUser.UserName).subscribe(function () {
            for (var i = 0; i < _this.currentUser.ProjectSet.length; i++) {
                var project = _this.currentUser.ProjectSet[i];
                if (project.Name === "Todo App") {
                    _this.project = project;
                    _this.generateExampleCode();
                    break;
                }
            }
        });
    };
    GettingStartedComponent.prototype.copy = function () {
        var textarea = document.createElement("textarea");
        textarea.innerHTML = this.exampleCode;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
    };
    GettingStartedComponent.prototype.createProject = function () {
        var _this = this;
        this.project = this.projectService.createProjectTodo();
        this.projectService.saveChanges().subscribe(function () {
            _this.generateExampleCode();
        });
    };
    GettingStartedComponent.prototype.generateExampleCode = function () {
        this.exampleCode =
            "<!DOCTYPE html>\n<html>\n<head>\n    <title>Todo App</title>\n    <meta charset=\"utf-8\">\n    <link rel=\"stylesheet\" href=\"" + location.origin + "/assets/examples/todo-app/index.css?v=" + this.version + "\" />\n</head>\n<body>\n    <div id=\"todo-app\">\n        <header>\n            <h1 id=\"project-name\">Todo App</h1>\n            <h2>User: <span id=\"user-name\">Guest</span></h2>\n            <div class=\"hide\" id=\"status\">\n                Please wait ...\n            </div>\n        </header>\n        <div id=\"main\">\n            <input autofocus=\"\" id=\"new-item-input\" onkeyup=\"app.createItem(event)\" placeholder=\"What needs to be done?\" >\n            <ul id=\"todo-list\">\n                <li id=\"todo-item-0\">\n                    <input class=\"toggle\" id=\"item-input-0\" onclick=\"app.updateItem(0);\" type=\"checkbox\" />\n                    <label for=\"item-input-0\" id=\"item-label-0\">Todo item</label>\n                    <button class=\"destroy\" id=\"item-button-0\" onclick=\"app.removeItem(0);\"></button>\n                </li>\n            </ul>\n        </div>\n    </div>\n    <footer id=\"info\">\n        Powered by <a href=\"https://backbone.forcrowd.org\" target=\"_blank\">Backbone</a><br />\n        Design by <a href=\"http://todomvc.com\" target=\"_blank\">TodoMVC</a><br />\n        Version " + this.version + "<br />\n    </footer>\n    <script type=\"text/javascript\">\n\n        var app = this.app = {\n\n            /* Access token of your Backbone account\n             * Please be aware that this is not the best security practice! */\n            currentUserToken: \"" + this.currentUser.token.access_token + "\",\n\n            /* Project ID of your 'Todo App' */\n            projectId: " + this.project.Id + "\n        };\n\n    </script>\n    <script type=\"text/javascript\" src=\"" + location.origin + "/assets/examples/todo-app/index.js?v=" + this.version + "\"></script>\n</body>\n</html>";
    };
    return GettingStartedComponent;
}());
export { GettingStartedComponent };
