<div class="m-16">
  <h1>Change Password</h1>

  <mat-card>
    <form #changePasswordForm="ngForm" autocomplete="off">
      <div class="g-mt-20 m-16" [ngClass]="{'has-error': CurrentPassword.dirty && CurrentPassword.invalid}">
        <mat-form-field>
          <input matInput id="CurrentPassword" name="CurrentPassword" type="password" placeholder="Current password"
            [(ngModel)]="bindingModel.CurrentPassword" #CurrentPassword="ngModel" class="form-control" required />
        </mat-form-field>
      </div>
      <div class="g-mt-20 m-16" [ngClass]="{'has-error': NewPassword.dirty && NewPassword.invalid}">
        <mat-form-field>
          <input matInput id="NewPassword" name="NewPassword" type="password" placeholder="New password" [(ngModel)]="bindingModel.NewPassword"
            #NewPassword="ngModel" class="form-control" required />
        </mat-form-field>
      </div>
      <div class="g-mt-20 m-16" [ngClass]="{'has-error': ConfirmPassword.dirty && ConfirmPassword.invalid}">
        <mat-form-field>
          <input matInput id="ConfirmPassword" name="ConfirmPassword" type="password" placeholder="Confirm new password"
            [(ngModel)]="bindingModel.ConfirmPassword" #ConfirmPassword="ngModel" class="form-control" required />
        </mat-form-field>
      </div>
      <div class="g-mt-20 m-16">
        <button mat-flat-button (click)="changePassword()" [disabled]="changePasswordForm.form.invalid || isBusy">
          Save
        </button>
        <button mat-flat-button (click)="cancel()" [disabled]="isBusy" color="warn" class="mat-m02">
          Cancel
        </button>
      </div>
    </form>
  </mat-card>
</div>
