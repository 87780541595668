import { EventEmitter, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { Project, ElementFieldDataType, ProjectService } from "@forcrowd/backbone-client-core";
var ElementCellManagerComponent = /** @class */ (function () {
    function ElementCellManagerComponent(projectService) {
        this.projectService = projectService;
        this.project = null;
        this.projectOwner = null;
        this.isEditingChanged = new EventEmitter();
        this.elementCellDataSource = new MatTableDataSource([]);
        this.elementCellDisplayedColumns = ["elementItem", "value", "createdOn"];
        this.elementFieldDataType = ElementFieldDataType;
        this.fields = {
            elementItem: null,
            elementFilter: null,
            elementFieldFilter: null,
            selectedElementCell: null,
        };
    }
    Object.defineProperty(ElementCellManagerComponent.prototype, "elementFilter", {
        get: function () {
            return this.fields.elementFilter;
        },
        set: function (value) {
            if (this.fields.elementFilter !== value) {
                this.fields.elementFilter = value;
                this.elementFieldFilter = value ? value.ElementFieldSet[0] : null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementCellManagerComponent.prototype, "elementFieldFilter", {
        get: function () {
            return this.fields.elementFieldFilter;
        },
        set: function (value) {
            if (this.fields.elementFieldFilter !== value) {
                this.fields.elementFieldFilter = value;
                this.elementCellDataSource.data = value ? value.ElementCellSet : [];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementCellManagerComponent.prototype, "selectedElementCell", {
        get: function () {
            return this.fields.selectedElementCell;
        },
        set: function (value) {
            if (this.fields.selectedElementCell !== value) {
                this.fields.selectedElementCell = value;
                this.isEditingChanged.emit(value ? true : false);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementCellManagerComponent.prototype, "selectedElementDecimalValue", {
        get: function () {
            if (!this.selectedElementCell || !this.selectedElementCell.UserElementCellSet[0])
                return null;
            return this.selectedElementCell.UserElementCellSet[0].DecimalValue;
        },
        set: function (value) {
            if (!this.selectedElementCell)
                return;
            // If there is no userElementCell, create it
            if (!this.selectedElementCell.UserElementCellSet[0])
                this.projectService.createUserElementCell(this.selectedElementCell, value);
            this.selectedElementCell.UserElementCellSet[0].DecimalValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementCellManagerComponent.prototype, "isBusy", {
        get: function () {
            return this.projectService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ElementCellManagerComponent.prototype.cancelElementCell = function () {
        this.projectService.rejectChangesElementCell(this.selectedElementCell);
        this.selectedElementCell = null;
    };
    ElementCellManagerComponent.prototype.editElementCell = function (elementCell) {
        this.selectedElementCell = elementCell;
    };
    ElementCellManagerComponent.prototype.ngOnInit = function () {
        this.elementFilter = this.project.ElementSet[0];
    };
    ElementCellManagerComponent.prototype.saveElementCell = function () {
        var _this = this;
        this.projectService.saveChanges()
            .subscribe(function () {
            _this.selectedElementCell = null;
        });
    };
    ElementCellManagerComponent.prototype.submitDisabled = function () {
        return this.isBusy || this.selectedElementCell.entityAspect.getValidationErrors().length > 0;
    };
    ElementCellManagerComponent.prototype.trackBy = function (index, elementCell) {
        return elementCell.Id;
    };
    return ElementCellManagerComponent;
}());
export { ElementCellManagerComponent };
