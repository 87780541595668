import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, ProjectService, NotificationService } from "@forcrowd/backbone-client-core";
import { settings } from "../../settings/settings";
var ProjectManagerComponent = /** @class */ (function () {
    function ProjectManagerComponent(activatedRoute, authService, projectService, notificationService, router) {
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.projectService = projectService;
        this.notificationService = notificationService;
        this.router = router;
        this.isEditing = false;
        this.project = null;
        this.selectedTabIndex = 0;
        this.projectOwner = null;
        this.projectOverviewIsOpen = false;
        this.viewMode = "new"; // new | existing | view
    }
    Object.defineProperty(ProjectManagerComponent.prototype, "metadataUrl", {
        get: function () {
            return settings.serviceODataUrl + "/$metadata";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectManagerComponent.prototype, "projectBasicApiUrl", {
        get: function () {
            return settings.serviceODataUrl + "/Project(" + this.project.Id + ")";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectManagerComponent.prototype, "projectGuestExpandedApiUrl", {
        get: function () {
            return settings.serviceODataUrl + "/Project(" + this.project.Id + ")?$expand=User,ElementSet/ElementFieldSet,ElementSet/ElementItemSet/ElementCellSet";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectManagerComponent.prototype, "projectOwnerExpandedApiUrl", {
        get: function () {
            return settings.serviceODataUrl + "/Project(" + this.project.Id + ")?$expand=User,ElementSet/ElementFieldSet/UserElementFieldSet,ElementSet/ElementItemSet/ElementCellSet/UserElementCellSet";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectManagerComponent.prototype, "isBusy", {
        get: function () {
            return this.projectService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ;
    ProjectManagerComponent.prototype.cancelProject = function () {
        this.project.entityAspect.rejectChanges();
        var command = "/users/" + this.project.User.UserName;
        this.router.navigate([command]);
    };
    ProjectManagerComponent.prototype.canDeactivate = function () {
        if (!this.projectService.hasChanges()) {
            return true;
        }
        if (confirm("Discard changes?")) {
            this.projectService.rejectChanges();
            return true;
        }
        else {
            return false;
        }
    };
    ProjectManagerComponent.prototype.createProjectEmpty = function () {
        var _this = this;
        this.project = this.projectService.createProjectEmpty();
        this.projectService.saveChanges()
            .subscribe(function () {
            var command = "/projects/" + _this.project.Id + "/edit";
            _this.router.navigate([command]);
        });
    };
    ProjectManagerComponent.prototype.createProjectBasic = function () {
        var _this = this;
        this.project = this.projectService.createProjectBasic();
        this.projectService.saveChanges()
            .subscribe(function () {
            var command = "/projects/" + _this.project.Id + "/edit";
            _this.router.navigate([command]);
        });
    };
    ProjectManagerComponent.prototype.createProjectParentChild = function () {
        var _this = this;
        this.project = this.projectService.createProjectParentChild();
        this.projectService.saveChanges()
            .subscribe(function () {
            var command = "/projects/" + _this.project.Id + "/edit";
            _this.router.navigate([command]);
        });
    };
    ProjectManagerComponent.prototype.createProjectTodo = function () {
        var _this = this;
        this.project = this.projectService.createProjectTodo();
        this.projectService.saveChanges()
            .subscribe(function () {
            var command = "/projects/" + _this.project.Id + "/edit";
            _this.router.navigate([command]);
        });
    };
    ProjectManagerComponent.prototype.copyApiLink = function (val) {
        var sel = document.createElement('textarea');
        sel.style.position = 'fixed';
        sel.style.left = '0';
        sel.style.top = '0';
        sel.style.opacity = '0';
        sel.value = val;
        document.body.appendChild(sel);
        sel.focus();
        sel.select();
        document.execCommand('copy');
        document.body.removeChild(sel);
        this.notificationService.notification.next("Api link copied to clipboard");
    };
    ProjectManagerComponent.prototype.manageProject = function () {
        this.project.entityAspect.rejectChanges();
        var command = "/projects/" + this.project.Id + "/edit";
        this.router.navigate([command]);
    };
    ProjectManagerComponent.prototype.isEditingChanged = function (isEditing) {
        this.isEditing = isEditing;
    };
    ProjectManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.viewMode = this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1].path;
        if (this.viewMode === "edit")
            this.viewMode = "existing";
        if (Number.isNaN(Number(this.viewMode)) === false)
            this.viewMode = "view";
        if (this.viewMode !== "new") {
            var projectId = this.activatedRoute.snapshot.params["project-id"];
            this.projectService.getProjectExpanded(projectId)
                .subscribe(function (project) {
                _this.projectOwner = project.User;
                _this.user = _this.authService.currentUser;
                if (_this.viewMode === "existing" && _this.projectOwner !== _this.user) {
                    _this.router.navigate(["/projects/" + project.Id]);
                }
                // Not found, navigate to 404
                if (!project) {
                    var url = window.location.href.replace(window.location.origin, "");
                    _this.router.navigate(["/app/not-found", { url: url }]);
                    return;
                }
                _this.project = project;
            });
        }
    };
    ProjectManagerComponent.prototype.saveProject = function () {
        this.projectService.saveChanges().subscribe();
    };
    ProjectManagerComponent.prototype.selectedTabChanged = function ($event) {
        this.selectedTabIndex = $event.index;
    };
    ProjectManagerComponent.prototype.submitDisabled = function (entity) {
        var hasValidationErrors = this.project.entityAspect.getValidationErrors().length > 0;
        return this.isBusy || hasValidationErrors;
    };
    return ProjectManagerComponent;
}());
export { ProjectManagerComponent };
