<div class="text-center bg">
  <div class="wrapper">
    <div id="landing-img" class="img"></div>
    <div id="landing-text">
      <div class="title m-t-120">Lightweight API service for rapid application prototyping</div>
      <div class="subtitle">Backbone is an open source software with a ready to use API made for smooth web and mobile application building</div>
      <div class="g-mt-20 btn">
        <button mat-raised-button routerLink="/app/getting-started" class="button">Getting started</button>
      </div>
    </div>
  </div>
</div>

<div class="landing-content">
  <div class="landing">
    <div class="p-50">
      <img src="/assets/images/template.png" alt="Easy to start">Easy to start
      <div>
        Select from a variety of templates made for applications. You can also get, create, update and test API
        generated documenation.
      </div>
    </div>
    <div class="p-50">
      <img src="/assets/images/api.png" alt="Ready to use API">Ready to use API
      <div>
        Backbone lets you deliver accurate, fast, and secure APIs.You only have to worry about your client or mobile
        app!
      </div>
    </div>
    <div class="p-50">
      <img src="/assets/images/build.png" alt="Built-in Authentication">Built-in Authentication
      <div>
        All account related actions are available. You can login, logout, register/forget passcode and even use the
        tool as a guest.
      </div>
    </div>
    <div class="p-50">
      <img src="/assets/images/open-source.png" alt="Open Source">Open Source
      <div>
        A free API tool, with network-accessibility services and open access to services for both consumers and
        developers.
      </div>
    </div>
  </div>
</div>
