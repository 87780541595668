import { moveItemInArray } from "@angular/cdk/drag-drop";
import { SelectionModel } from "@angular/cdk/collections";
import { EventEmitter, OnInit } from "@angular/core";
import { MatDialog, MatTable } from "@angular/material";
import { Project, ProjectService, NotificationService } from "@forcrowd/backbone-client-core";
import { finalize } from "rxjs/operators";
import { RemoveConfirmComponent } from "./remove-confirm.component";
var ElementManagerComponent = /** @class */ (function () {
    function ElementManagerComponent(projectService, notificationService, dialog) {
        this.projectService = projectService;
        this.notificationService = notificationService;
        this.dialog = dialog;
        this.project = null;
        this.projectOwner = null;
        this.isEditingChanged = new EventEmitter();
        this.selection = new SelectionModel(true, []);
        this.elementDisplayedColumns = ["select", "name", "createdOn"];
        this.fields = {
            project: null,
            selectedElement: null,
        };
    }
    Object.defineProperty(ElementManagerComponent.prototype, "selectedElement", {
        get: function () {
            return this.fields.selectedElement;
        },
        set: function (value) {
            if (this.fields.selectedElement !== value) {
                this.fields.selectedElement = value;
                this.isEditingChanged.emit(value ? true : false);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementManagerComponent.prototype, "isBusy", {
        get: function () {
            return this.projectService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ElementManagerComponent.prototype.addElement = function () {
        this.selectedElement = this.projectService.createElement({
            Project: this.project,
            Name: "New element",
            SortOrder: this.project.ElementSet.length,
        });
    };
    ElementManagerComponent.prototype.cancelElement = function () {
        this.projectService.rejectChangesElement(this.selectedElement);
        this.selectedElement = null;
    };
    ElementManagerComponent.prototype.editElement = function (element) {
        this.selectedElement = element;
    };
    ElementManagerComponent.prototype.ngOnInit = function () {
        this.project.ElementSet.sort(function (a, b) { return a.SortOrder - b.SortOrder; });
        if (!this.projectOwner)
            this.elementDisplayedColumns.splice(0, 1);
    };
    ElementManagerComponent.prototype.removeElement = function () {
        var _this = this;
        var isRemove = true;
        var dialogRef = this.dialog.open(RemoveConfirmComponent);
        dialogRef.afterClosed().subscribe(function (confirmed) {
            if (!confirmed)
                return;
            if (_this.selection.selected.length > 0) {
                _this.selection.selected.forEach(function (selected) {
                    if (selected.ParentFieldSet.length > 0) {
                        isRemove = false;
                        var parentField = selected.ParentFieldSet[0].Name;
                        var errorMsg = "This Element (" + selected.Name + ") could not be removed, because firstly related field (" + parentField + ") must be removed!";
                        _this.notificationService.notification.next(errorMsg);
                    }
                    if (isRemove)
                        _this.projectService.removeElement(selected);
                });
                _this.projectService.saveChanges().pipe(finalize(function () {
                    _this.matTable.renderRows();
                    _this.selection.clear();
                })).subscribe();
            }
        });
    };
    ElementManagerComponent.prototype.onListDrop = function ($event) {
        // Update data & render
        var prevIndex = this.project.ElementSet.findIndex(function (d) { return d === $event.item.data; });
        moveItemInArray(this.project.ElementSet, prevIndex, $event.currentIndex);
        this.matTable.renderRows();
        // Update elements' SortOrder property
        this.project.ElementSet.map(function (e, i) {
            if (e.SortOrder !== i) {
                e.SortOrder = i;
            }
        });
        // Save
        this.projectService.saveChanges().subscribe();
    };
    ElementManagerComponent.prototype.saveElement = function () {
        var _this = this;
        this.projectService.saveChanges().subscribe(function () {
            _this.selectedElement = null;
        });
    };
    ElementManagerComponent.prototype.submitDisabled = function () {
        return this.isBusy || this.selectedElement.entityAspect.getValidationErrors().length > 0;
    };
    ElementManagerComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.project.ElementSet.length;
        return numSelected === numRows;
    };
    ElementManagerComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected() ?
            this.selection.clear() :
            this.project.ElementSet.forEach(function (row) { return _this.selection.select(row); });
    };
    ElementManagerComponent.prototype.trackBy = function (index, element) {
        return element.Id;
    };
    return ElementManagerComponent;
}());
export { ElementManagerComponent };
