<div class="m-16">
  <h1>Change Username</h1>

  <mat-card>
    <form #changeUserNameForm="ngForm" autocomplete="off">
      <div class="g-mt-20 m-16" [ngClass]="{'has-error': UserName.dirty && UserName.invalid}">
        <mat-form-field>
          <input matInput id="UserName" name="UserName" type="text" placeholder="Username" [(ngModel)]="bindingModel.UserName"
            #UserName="ngModel" aria-describedby="UserNameHelp" required />
          <mat-hint id="UserNameHelp">
            No special characters allowed, except dash "-" and underscore "_".
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="g-mt-20 m-16">
        <button mat-flat-button (click)="changeUserName()" [disabled]="changeUserNameForm.form.invalid || submitDisabled()">
          Save
        </button>
        <button mat-flat-button (click)="cancel()" [disabled]="isBusy" color="warn" class="mat-m02">
          Cancel
        </button>
      </div>
    </form>
  </mat-card>
</div>
