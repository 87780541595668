<div class="m-16">
  <h2>
    <button mat-icon-button routerLink="/app/admin">
      <mat-icon aria-label="Back">keyboard_arrow_left</mat-icon>
    </button>
    Admin dashboard
  </h2>

  <h1 class="m-16">All Projects</h1>

  <div class="g-mt-20 m-16">
    <mat-card [hidden]="dataSource.data.length > 0">
      <div class="noResults">
        Nothing to display!
      </div>
    </mat-card>

    <div [hidden]="dataSource.data.length === 0">
      <mat-card class="mb-150">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Project </th>
            <td mat-cell *matCellDef="let project"> <a routerLink="/projects/{{ project.Id }}">{{ project.Name }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef> User </th>
            <td mat-cell *matCellDef="let project"> <a routerLink="/users/{{ project.User.UserName }}">{{
                project.User.UserName }}</a> </td>
          </ng-container>

          <ng-container matColumnDef="ratingCount">
            <th mat-header-cell *matHeaderCellDef> # Ratings </th>
            <td mat-cell *matCellDef="let project"> {{ project.RatingCount }} </td>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef> Created On </th>
            <td mat-cell *matCellDef="let project"> {{ project.CreatedOn | amTimeAgo }} </td>
          </ng-container>

          <ng-container matColumnDef="modifiedOn">
            <th mat-header-cell *matHeaderCellDef> Modified On </th>
            <td mat-cell *matCellDef="let project"> {{ project.ModifiedOn | amTimeAgo }} </td>
          </ng-container>

          <ng-container matColumnDef="functions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let project">
              <div class="pull-right">
                <button mat-button (click)="updateComputedFields(project)" class="mat-m02">
                  <i class="fa fa-refresh" aria-hidden="true"></i> Update
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card>
    </div>
  </div>
</div>
