import { Routes } from "@angular/router";
import { ICoreConfig } from "@forcrowd/backbone-client-core";
import { settings } from "../../settings/settings";
// Components
import { ContributorsComponent } from "./components/contributors.component";
import { GettingStartedComponent } from "./components/getting-started.component";
import { LandingPageComponent } from "./components/landing-page.component";
import { ProfileComponent } from "./components/profile.component";
import { SearchComponent } from "./components/search.component";
// Services
import { AppProjectService } from "./app-project.service";
import { AuthGuard } from "./auth-guard.service";
import { CanDeactivateGuard } from "./can-deactivate-guard.service";
import { DynamicTitleResolve } from "./dynamic-title-resolve.service";
export { AppProjectService, AuthGuard, CanDeactivateGuard, DynamicTitleResolve };
// TODO: Remove! Only here to test appErrorHandler on production
var ExComponent = /** @class */ (function () {
    function ExComponent() {
        throw new Error("test");
    }
    return ExComponent;
}());
export { ExComponent };
var ɵ0 = { title: "Home" }, ɵ1 = { title: "Contributors" }, ɵ2 = { title: "Getting Started" }, ɵ3 = { title: "Search" };
var appCoreRoutes = [
    // Core
    { path: "", component: LandingPageComponent, data: ɵ0 },
    { path: "app/home", redirectTo: "", pathMatch: "full" },
    { path: "app/contributors", component: ContributorsComponent, data: ɵ1 },
    { path: "app/getting-started", component: GettingStartedComponent, data: ɵ2 },
    { path: "app/search", component: SearchComponent, data: ɵ3 },
    { path: "app/ex", component: ExComponent },
    // Users
    { path: "users/:username", component: ProfileComponent, resolve: { title: DynamicTitleResolve } },
];
var coreConfig = {
    environment: settings.environment,
    serviceApiUrl: settings.serviceApiUrl,
    serviceODataUrl: settings.serviceODataUrl
};
var AppCoreModule = /** @class */ (function () {
    function AppCoreModule() {
    }
    return AppCoreModule;
}());
export { AppCoreModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
