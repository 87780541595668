
<h1 class="m-16">{{dataSource.data.length === 1 ? "Result" : "Results"}}</h1>

<div class="container g-mt-20 m-16">

  <mat-progress-bar mode="indeterminate" [hidden]="!isBusy"></mat-progress-bar>
  <div class="g-mt-20 g-mb-250">

    <mat-card [hidden]="!hasResult || dataSource.data.length > 0">
      <div class="noResults">
        No records found!
      </div>
    </mat-card>

    <mat-card [hidden]="!hasResult || dataSource.data.length === 0">
      <table mat-table [dataSource]="dataSource" [trackBy]="trackBy">

        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Project </th>
          <td mat-cell *matCellDef="let project"><a routerLink="/projects/{{ project.Id }}"> {{ project.Name }} </a></td>
        </ng-container>

        <!-- UserName -->
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef> User </th>
          <td mat-cell *matCellDef="let project"><a routerLink="/users/{{ project.User.UserName }}"> {{ project.User.UserName }} </a></td>
        </ng-container>

        <!-- RatingCount -->
        <ng-container matColumnDef="ratingCount">
          <th mat-header-cell *matHeaderCellDef> # Ratings </th>
          <td mat-cell *matCellDef="let project"> {{ project.RatingCount }} </td>
        </ng-container>

        <!-- CreatedOn -->
        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef> Created On</th>
          <td mat-cell *matCellDef="let project"> {{ project.CreatedOn | amTimeAgo }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card>
  </div>
</div>
