<div class="m-16">
  <h1>Add Password</h1>

  <mat-card>
    <form #addPasswordForm="ngForm" autocomplete="off">
      <div [ngClass]="{'has-error': Password.dirty && !Password.invalid}" class="g-mt-20 m-16">
        <mat-form-field>
          <input matInput id="Password" name="Password" type="password" [(ngModel)]="model.Password" #Password="ngModel" placeholder="Password" required />
        </mat-form-field>
      </div>
      <div [ngClass]="{'has-error': ConfirmPassword.dirty && !ConfirmPassword.invalid}" class="g-mt-20 m-16">
        <mat-form-field>
          <input matInput id="ConfirmPassword" name="ConfirmPassword" type="password" [(ngModel)]="model.ConfirmPassword" placeholder="Confirm Password" #ConfirmPassword="ngModel" required />
        </mat-form-field>
      </div>
      <div class="g-mt-20 m-16">
        <button mat-raised-button (click)="addPassword()" [disabled]="addPasswordForm.form.invalid || isBusy" type="button" color="primary">
          Save
        </button>
        <button mat-raised-button (click)="cancel()" [disabled]="isBusy" color="warn" type="button" class="mat-m02">
          Cancel
        </button>
      </div>
    </form>
  </mat-card>

</div>
