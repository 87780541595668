<h3 mat-dialog-title>Confirmation</h3>

<mat-dialog-content>
  Are you sure do you want to remove?
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" color="primary">Remove</button>
  <button mat-button [mat-dialog-close]="false" color="warn" class="mat-m02">Cancel</button>
</mat-dialog-actions>
