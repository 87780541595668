import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { AuthService, NotificationService } from "@forcrowd/backbone-client-core";
import { flatMap, map } from "rxjs/operators";
import { AccountService } from "./account.service";
import { AccountRemoveConfirmComponent } from "./account-delete-confirm.component";
var AccountOverviewComponent = /** @class */ (function () {
    function AccountOverviewComponent(accountService, authService, notificationService, dialog, router) {
        this.accountService = accountService;
        this.authService = authService;
        this.notificationService = notificationService;
        this.dialog = dialog;
        this.router = router;
    }
    Object.defineProperty(AccountOverviewComponent.prototype, "currentUser", {
        get: function () {
            return this.authService.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccountOverviewComponent.prototype, "displayConfirmEmail", {
        get: function () {
            return !(this.currentUser.EmailConfirmed
                || (this.currentUser.Roles[0].Role.Name === "Guest"
                    && !this.currentUser.EmailConfirmationSentOn));
        },
        enumerable: true,
        configurable: true
    });
    AccountOverviewComponent.prototype.deleteAccount = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AccountRemoveConfirmComponent);
        dialogRef.afterClosed().subscribe(function (confirmed) {
            if (!confirmed)
                return;
            _this.accountService.deleteAccount().pipe(flatMap(function () {
                _this.notificationService.notification.next("Your account has been deleted!");
                return _this.authService.init().pipe(map(function () {
                    _this.router.navigate(["/"]);
                }));
            })).subscribe();
        });
    };
    return AccountOverviewComponent;
}());
export { AccountOverviewComponent };
