<div *ngIf="project" class="m-16">

  <!-- Element list -->
  <div *ngIf="!selectedElement">

    <div *ngIf="projectOwner" class="g-mt-20">
      <button mat-button [disabled]="isBusy" (click)="addElement()">
        <i class="material-icons">add</i>
        Create new
      </button>
      <button mat-button [disabled]="isBusy || selection?.selected?.length === 0" (click)="removeElement()" [hidden]="project.ElementSet.length === 0">
        <i class="material-icons">delete</i>
        Remove
      </button>
    </div>

    <table mat-table [dataSource]="project.ElementSet" [trackBy]="trackBy" cdkDropList [cdkDropListData]="project.ElementSet" (cdkDropListDropped)="onListDrop($event)" class="mat-card-std g-mb-250 g-mt-20 g-mt-20 drag-list">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Element </th>
        <td mat-cell *matCellDef="let element">
          <a *ngIf="projectOwner" (click)="editElement(element)">{{ element.Name }}</a>
          <span *ngIf="!projectOwner"> {{ element.Name }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef> Created On</th>
        <td mat-cell *matCellDef="let element"> {{ element.CreatedOn | amTimeAgo }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="elementDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: elementDisplayedColumns;" (click)="selection.toggle(row)" cdkDrag cdkDragLockAxis="y" [cdkDragData]="row" class="drop-box"></tr>
    </table>

  </div>

  <!-- Element add/edit -->
  <div *ngIf="selectedElement">

    <mat-card class="g-mb-250">
      <form #elementForm="ngForm" autocomplete="off">
        <div class="g-mt-40 m-16">
          <div [ngClass]="{ 'has-error': selectedElement.entityAspect.getValidationErrors('Name').length > 0 }">
            <mat-form-field>
              <input matInput id="Name" name="Name" type="text" [(ngModel)]="selectedElement.Name" placeholder="Name" />
            </mat-form-field>
          </div>

          <div class="g-mt-20">
            <button mat-flat-button (click)="saveElement()" [disabled]="submitDisabled()">
              Save element
            </button>
            <button mat-flat-button (click)="cancelElement()" [disabled]="isBusy" color="warn" class="mat-m02">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>

</div>
