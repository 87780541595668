import { Routes } from "@angular/router";
// Components
import { AdminOverviewComponent } from "./admin-overview.component";
import { ProjectsComponent } from "./projects.component";
// Services
import { AdminGuard } from "./admin-guard.service";
var ɵ0 = { title: "Admin Overview" }, ɵ1 = { title: "Projects" };
// Routes
var adminRoutes = [
    { path: "app/admin", component: AdminOverviewComponent, canActivate: [AdminGuard], data: ɵ0 },
    { path: "app/admin/projects", component: ProjectsComponent, canActivate: [AdminGuard], data: ɵ1 }
];
var AdminModule = /** @class */ (function () {
    function AdminModule() {
    }
    return AdminModule;
}());
export { AdminModule };
export { ɵ0, ɵ1 };
