/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-email.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./confirm-email.component";
import * as i10 from "@angular/router";
import * as i11 from "./account.service";
import * as i12 from "@forcrowd/backbone-client-core";
var styles_ConfirmEmailComponent = [i0.styles];
var RenderType_ConfirmEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmEmailComponent, data: {} });
export { RenderType_ConfirmEmailComponent as RenderType_ConfirmEmailComponent };
export function View_ConfirmEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "m-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confirm Email"])), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "g-mt-20 m-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(5, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" A confirmation email has been sent to your email address. "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please use the link in the email to confirm your email address. "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you didn't receive a confirmation email, please send it again."])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "g-mt-20 m-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["mat-flat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resendConfirmationEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, [" Resend Confirmation Email "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isBusy; _ck(_v, 16, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 16).disabled || null); var currVal_1 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_0, currVal_1); }); }
export function View_ConfirmEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirm-email", [], null, null, null, View_ConfirmEmailComponent_0, RenderType_ConfirmEmailComponent)), i1.ɵdid(1, 114688, null, 0, i9.ConfirmEmailComponent, [i10.ActivatedRoute, i11.AccountService, i12.NotificationService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmEmailComponentNgFactory = i1.ɵccf("confirm-email", i9.ConfirmEmailComponent, View_ConfirmEmailComponent_Host_0, {}, {}, []);
export { ConfirmEmailComponentNgFactory as ConfirmEmailComponentNgFactory };
