/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-remove-project.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./profile-remove-project.component";
var styles_ProfileRemoveProjectComponent = [i0.styles];
var RenderType_ProfileRemoveProjectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileRemoveProjectComponent, data: {} });
export { RenderType_ProfileRemoveProjectComponent as RenderType_ProfileRemoveProjectComponent };
export function View_ProfileRemoveProjectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Confirmation"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, [" Are you sure you want to remove this selection?\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "mat-m02"], ["color", "primary"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(10, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Remove"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "mat-m02"], ["color", "warn"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(14, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = "primary"; _ck(_v, 9, 0, currVal_4); var currVal_5 = true; _ck(_v, 10, 0, currVal_5); var currVal_9 = "warn"; _ck(_v, 13, 0, currVal_9); var currVal_10 = false; _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 9).disabled || null); var currVal_2 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_3 = i1.ɵnov(_v, 10).ariaLabel; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = (i1.ɵnov(_v, 13).disabled || null); var currVal_7 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_8 = i1.ɵnov(_v, 14).ariaLabel; _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_ProfileRemoveProjectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-remove-project", [], null, null, null, View_ProfileRemoveProjectComponent_0, RenderType_ProfileRemoveProjectComponent)), i1.ɵdid(1, 49152, null, 0, i8.ProfileRemoveProjectComponent, [], null, null)], null, null); }
var ProfileRemoveProjectComponentNgFactory = i1.ɵccf("profile-remove-project", i8.ProfileRemoveProjectComponent, View_ProfileRemoveProjectComponent_Host_0, {}, {}, []);
export { ProfileRemoveProjectComponentNgFactory as ProfileRemoveProjectComponentNgFactory };
