import { SelectionModel } from "@angular/cdk/collections";
import { EventEmitter, OnInit } from "@angular/core";
import { MatDialog, MatTable } from "@angular/material";
import { Project, ProjectService, NotificationService } from "@forcrowd/backbone-client-core";
import { finalize } from "rxjs/operators";
import { RemoveConfirmComponent } from "./remove-confirm.component";
var ElementItemManagerComponent = /** @class */ (function () {
    function ElementItemManagerComponent(projectService, notificationService, dialog) {
        this.projectService = projectService;
        this.notificationService = notificationService;
        this.dialog = dialog;
        this.project = null;
        this.projectOwner = null;
        this.isEditingChanged = new EventEmitter();
        this.selection = new SelectionModel(true, []);
        this.elementItemDisplayedColumns = ["select", "element", "name", "createdOn"];
        this.fields = {
            selectedElementItem: null,
            elementFilter: null,
        };
    }
    Object.defineProperty(ElementItemManagerComponent.prototype, "selectedElementItem", {
        get: function () {
            return this.fields.selectedElementItem;
        },
        set: function (value) {
            if (this.fields.selectedElementItem !== value) {
                this.fields.selectedElementItem = value;
                this.isEditingChanged.emit(value ? true : false);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementItemManagerComponent.prototype, "elementFilter", {
        get: function () {
            return this.fields.elementFilter;
        },
        set: function (value) {
            if (this.fields.elementFilter !== value) {
                this.fields.elementFilter = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementItemManagerComponent.prototype, "isBusy", {
        get: function () {
            return this.projectService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ElementItemManagerComponent.prototype.addElementItem = function () {
        this.selectedElementItem = this.projectService.createElementItem({
            Element: this.elementFilter,
            Name: "New item"
        });
    };
    ElementItemManagerComponent.prototype.cancelElementItem = function () {
        var elementItem = this.selectedElementItem;
        this.selectedElementItem = null;
        this.projectService.rejectChangesElementItem(elementItem);
    };
    ElementItemManagerComponent.prototype.editElementItem = function (elementItem) {
        this.selectedElementItem = elementItem;
    };
    ElementItemManagerComponent.prototype.ngOnInit = function () {
        this.elementFilter = this.project.ElementSet[0];
        if (!this.projectOwner)
            this.elementItemDisplayedColumns.splice(0, 1);
    };
    ElementItemManagerComponent.prototype.removeElementItem = function () {
        var _this = this;
        var isRemove = true;
        var dialogRef = this.dialog.open(RemoveConfirmComponent);
        dialogRef.afterClosed().subscribe(function (confirmed) {
            if (!confirmed)
                return;
            if (_this.selection.selected.length > 0) {
                _this.selection.selected.forEach(function (elementItem) {
                    if (elementItem.ParentCellSet.length > 0) {
                        isRemove = false;
                        var parentItem = elementItem.ParentCellSet[0].ElementItem.Name;
                        var parentField = elementItem.ParentCellSet[0].ElementField.Name;
                        var errorMsg = "This Items (" + elementItem.Name + ") could not be removed, because firstly related elements (" + parentItem + ", " + parentField + ") must be removed!";
                        _this.notificationService.notification.next(errorMsg);
                    }
                    if (isRemove)
                        _this.projectService.removeElementItem(elementItem);
                });
                _this.projectService.saveChanges().pipe(finalize(function () {
                    _this.matTable.renderRows();
                    _this.selection.clear();
                })).subscribe();
            }
        });
    };
    ElementItemManagerComponent.prototype.saveElementItem = function () {
        var _this = this;
        this.projectService.saveElementItem(this.selectedElementItem)
            .subscribe(function () {
            _this.selectedElementItem = null;
        });
    };
    ElementItemManagerComponent.prototype.submitDisabled = function () {
        return this.isBusy || this.selectedElementItem.entityAspect.getValidationErrors().length > 0;
    };
    ElementItemManagerComponent.prototype.isAllSelected = function () {
        if (!this.elementFilter)
            return false;
        var numSelected = this.selection.selected.length;
        var numRows = this.elementFilter.ElementItemSet.length;
        return numSelected === numRows;
    };
    ElementItemManagerComponent.prototype.masterToggle = function () {
        var _this = this;
        if (!this.elementFilter)
            return;
        this.isAllSelected()
            ? this.selection.clear()
            : this.elementFilter.ElementItemSet.forEach(function (row) { return _this.selection.select(row); });
    };
    ElementItemManagerComponent.prototype.trackBy = function (index, elementItem) {
        return elementItem.Id;
    };
    return ElementItemManagerComponent;
}());
export { ElementItemManagerComponent };
