<mat-sidenav-container [ngClass]="{'container': !isHomePage(), 'landing': isHomePage()}">
  <mat-sidenav #sidenav mode="side" [opened]="displaySidebar" class="bottom-to-top">
    <mat-nav-list class="ml-0" dense>
      <mat-list-item class="logo-sidenav ml-0 logo-white" fxLayoutAlign="center center" mat-button routerLink="/">
        <img alt="Logo" src="/assets/images/backbone.png?v=0.1.0" class="backbone-icon">
        Backbone
      </mat-list-item>
    </mat-nav-list>

    <mat-list role="list">

      <mat-list-item class="list pt-20" role="listitem" fxLayoutAlign="center center">
        <button mat-flat-button class="new-project-btn w-160" routerLink="/projects/new">
          <i class="material-icons">add</i>
          New Project
        </button>
      </mat-list-item>

    </mat-list>

    <mat-list role="list" fxLayout="column" fxLayoutAlign="start start">

      <mat-list-item class="list" role="listitem" [hidden]="!currentUser.isAuthenticated()" fxLayoutAlign="center center">
        <button mat-button class="other-btn ta-l" routerLink="/">Dashboard</button>
      </mat-list-item>

      <mat-list-item class="list" role="listitem" [hidden]="currentUser.isAuthenticated()" fxLayoutAlign="center center">
        <button mat-button class="other-btn ta-l" routerLink="/app/account/login">Login</button>
      </mat-list-item>

      <mat-list-item class="list" role="listitem" [hidden]="currentUser.isAuthenticated()" fxLayoutAlign="center center">
        <button mat-button class="other-btn ta-l" routerLink="/app/account/register">Register</button>
      </mat-list-item>

      <mat-list-item class="list" role="listitem" [hidden]="!currentUser.isAuthenticated()" fxLayoutAlign="center center">
        <button mat-button class="other-btn ta-l" routerLink="/app/account">Account</button>
      </mat-list-item>

      <mat-list-item class="list" role="listitem" [hidden]="!currentUser.isAdmin()" fxLayoutAlign="center center">
        <button mat-button class="other-btn ta-l" routerLink="/app/admin">Admin</button>
      </mat-list-item>

    </mat-list>

  </mat-sidenav>

  <mat-sidenav-content [ngClass]="{'ml-250': !isHomePage(), 'ml-0': !displaySidebar || isHomePage()}">

    <mat-toolbar class="header">

      <button mat-button routerLink="/" class="logo-black">
        <img alt="Backbone" src="/assets/images/backbone-black.png?v=0.1.0" class="backbone-icon">
        Backbone
      </button>

      <span class="spacer"></span>

      <!-- Search Form -->
      <form #searchForm="ngForm" autocomplete="off">
        <input matInput class="searchInput" [ngClass]="{'searchInput-landing': isHomePage()}" id="searchKey" name="searchKey"
          (enter)="search()" [(ngModel)]="searchKey">
        <button mat-icon-button type="submit" class="searchIcon" (click)="search()">
          <i class="material-icons">search</i>
        </button>
      </form>

      <a class="link" fxShow.lt-md="true" fxShow.gt-sm="true" routerLink="/app/account/register" [hidden]="currentUser.isAuthenticated()">
        <span class="ml-1">Register</span>
      </a>

      <a *ngIf="!currentUser.isAuthenticated()" class="link" routerLink="/app/account/login" fxShow.lt-md="true"
        fxShow.gt-sm="true">
        <span class="ml-2" *ngIf="!currentUser.isAuthenticated()">Login</span>
      </a>

      <a mat-button [matMenuTriggerFor]="menu" fxShow.lt-md="false" fxShow.gt-sm="true">
        <mat-icon *ngIf="currentUser.isAuthenticated()">account_circle</mat-icon>
        <span class="ml-2" *ngIf="currentUser.isAuthenticated()">{{ currentUser.UserName }}</span>
      </a>

      <button mat-icon-button *ngIf="currentUser.isAuthenticated()" [matMenuTriggerFor]="menu" fxShow.lt-md="true"
        fxShow.gt-sm="false">
        <mat-icon>account_circle</mat-icon>
      </button>

      <mat-menu #menu="matMenu" overlapTrigger="false">
        <button mat-menu-item *ngIf="!displaySidebar" routerLink="/projects/new">
          <span>New Project</span>
        </button>
        <button mat-menu-item routerLink="/" [hidden]="!currentUser.isAuthenticated()">
          <span>Dashboard</span>
        </button>
        <button mat-menu-item routerLink="/app/account" [hidden]="!currentUser.isAuthenticated()">
          <span>Account</span>
        </button>
        <button mat-menu-item (click)="logout()" [hidden]="!currentUser.isAuthenticated()">
          <span>Logout</span>
        </button>
      </mat-menu>
    </mat-toolbar>

    <!-- forCrowd Backbone content -->
    <mat-sidenav-content class="content" [ngClass]="{'no-margin': isHomePage()}">

      <router-outlet></router-outlet>

      <div mat-sidenav-content [ngClass]="{'footer-landing sidenav-content-lp': isHomePage(), 'footer': !isHomePage(), 'footer-l-0': !displaySidebar}"
        class="sidenav-content" fxLayout="row">
        <a href="https://forcrowd.org" target="_blank" class="footer-left img">
          <img class="img" alt="Logo" src="/assets/images/Logo+Text_142x30.png?v=0.1.2">
        </a>
        <a mat-icon-button href="https://twitter.com/forCrowd" target="_blank" class="footer-left pl-10">
          <i class="fa fa-twitter fa-lg" aria-hidden="true"></i>
        </a>
        <a mat-icon-button href="https://github.com/forCrowd/Backbone" target="_blank" class="footer-left">
          <i class="fa fa-github fa-lg" aria-hidden="true"></i>
        </a>
        <a mat-icon-button href="mailto:contact.backbone@forCrowd.org" class="footer-left">
          <i class="fa fa-envelope fa-lg" aria-hidden="true"></i>
        </a>
        <span style="margin: auto;">&nbsp;</span>
        <a mat-button routerLink="/app/contributors" class="footer-right">
          Contributors
        </a>
        <a mat-button href="https://github.com/forCrowd/Backbone/blob/master/CHANGELOG.md" target="_blank" class="footer-right">
          Version 0.9.30
        </a>
      </div>
    </mat-sidenav-content>
    <!-- ./ forCrowd Backbone content -->

  </mat-sidenav-content>
</mat-sidenav-container>
