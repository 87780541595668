import { HttpClient } from "@angular/common/http";
import { AppEntityManager, AuthService } from "@forcrowd/backbone-client-core";
import { EntityQuery } from "breeze-client";
import { finalize, mergeMap, map } from "rxjs/operators";
import { settings } from "../../settings/settings";
var AdminService = /** @class */ (function () {
    function AdminService(appEntityManager, authService, httpClient) {
        this.appEntityManager = appEntityManager;
        this.authService = authService;
        this.httpClient = httpClient;
        this.appHttpClient = null;
        this.isBusyLocal = false; // Use this flag for functions that contain multiple http requests (e.g. saveChanges())
        this.appHttpClient = httpClient;
    }
    Object.defineProperty(AdminService.prototype, "currentUser", {
        get: function () {
            return this.authService.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdminService.prototype, "isBusy", {
        get: function () {
            return this.appEntityManager.isBusy || this.appHttpClient.isBusy || this.isBusyLocal;
        },
        enumerable: true,
        configurable: true
    });
    AdminService.prototype.getProjectSet = function (onlyCount, forceRefresh) {
        if (onlyCount === void 0) { onlyCount = false; }
        if (forceRefresh === void 0) { forceRefresh = false; }
        var query = EntityQuery.from("Project");
        if (onlyCount) {
            query = query.take(0).inlineCount(true);
        }
        else {
            query = query.expand(["User"])
                .orderByDesc("ModifiedOn");
        }
        return this.appEntityManager.executeQueryObservable(query, forceRefresh);
    };
    AdminService.prototype.getUser = function () {
        var query = EntityQuery
            .from("Users")
            .inlineCount(true)
            .orderByDesc("CreatedOn");
        return this.appEntityManager.executeQueryObservable(query).pipe(map(function (response) {
            return response;
        }));
    };
    AdminService.prototype.getProject = function () {
        var query = EntityQuery
            .from("Project")
            .inlineCount(true)
            .orderByDesc("CreatedOn");
        return this.appEntityManager.executeQueryObservable(query).pipe(map(function (response) {
            return response;
        }));
    };
    AdminService.prototype.saveChanges = function () {
        var _this = this;
        this.isBusyLocal = true;
        return this.authService.ensureAuthenticatedUser().pipe(mergeMap(function () {
            return _this.appEntityManager.saveChangesObservable();
        }), finalize(function () {
            _this.isBusyLocal = false;
        }));
    };
    AdminService.prototype.updateComputedFields = function (project) {
        var url = settings.serviceApiUrl + "/ProjectApi/" + project.Id + "/UpdateComputedFields";
        return this.httpClient.post(url, null);
    };
    return AdminService;
}());
export { AdminService };
