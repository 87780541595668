import { Routes } from "@angular/router";
import { NotFoundComponent } from "./not-found.component";
var ɵ0 = { title: "Not found" }, ɵ1 = { title: "Not found" };
var coreRoutes = [
    { path: "app/not-found", component: NotFoundComponent, data: ɵ0 },
    { path: "**", component: NotFoundComponent, data: ɵ1 }
];
var NotFoundModule = /** @class */ (function () {
    function NotFoundModule() {
    }
    return NotFoundModule;
}());
export { NotFoundModule };
export { ɵ0, ɵ1 };
