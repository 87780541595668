/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./home.component.ngfactory";
import * as i2 from "./home.component";
import * as i3 from "./profile.component.ngfactory";
import * as i4 from "./profile.component";
import * as i5 from "@forcrowd/backbone-client-core";
import * as i6 from "@angular/router";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/flex-layout";
import * as i9 from "@angular/common";
import * as i10 from "./landing-page.component";
var styles_LandingPageComponent = [];
var RenderType_LandingPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LandingPageComponent, data: {} });
export { RenderType_LandingPageComponent as RenderType_LandingPageComponent };
function View_LandingPageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "home", [], null, null, null, i1.View_HomeComponent_0, i1.RenderType_HomeComponent)), i0.ɵdid(2, 49152, null, 0, i2.HomeComponent, [], null, null)], null, null); }
function View_LandingPageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "profile", [], null, null, null, i3.View_ProfileComponent_0, i3.RenderType_ProfileComponent)), i0.ɵdid(2, 245760, null, 0, i4.ProfileComponent, [i5.AuthService, i6.ActivatedRoute, i7.MatDialog, i5.ProjectService, i6.Router, i8.ObservableMedia], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LandingPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LandingPageComponent_1)), i0.ɵdid(1, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LandingPageComponent_2)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.currentUser.isAuthenticated(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.currentUser.isAuthenticated(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LandingPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "landing-page", [], null, null, null, View_LandingPageComponent_0, RenderType_LandingPageComponent)), i0.ɵdid(1, 245760, null, 0, i10.LandingPageComponent, [i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingPageComponentNgFactory = i0.ɵccf("landing-page", i10.LandingPageComponent, View_LandingPageComponent_Host_0, {}, {}, []);
export { LandingPageComponentNgFactory as LandingPageComponentNgFactory };
