import { OnDestroy, OnInit } from "@angular/core";
import { ObservableMedia } from "@angular/flex-layout";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { Angulartics2GoogleGlobalSiteTag } from "angulartics2/gst";
import { AuthService, NotificationService } from "@forcrowd/backbone-client-core";
import { mergeMap, map, filter } from "rxjs/operators";
var CoreComponent = /** @class */ (function () {
    function CoreComponent(activatedRoute, angulartics, authService, matSnackBar, notificationService, titleService, router, media) {
        this.activatedRoute = activatedRoute;
        this.angulartics = angulartics;
        this.authService = authService;
        this.matSnackBar = matSnackBar;
        this.notificationService = notificationService;
        this.titleService = titleService;
        this.router = router;
        this.media = media;
        this.currentUser = null;
        this.mediaQuery = "";
        this.searchKey = "";
        this.subscriptions = [];
        this.angulartics.startTracking();
        this.currentUser = this.authService.currentUser;
    }
    Object.defineProperty(CoreComponent.prototype, "displaySidebar", {
        get: function () {
            return !(this.isHomePage() || (this.mediaQuery === "xs" || this.mediaQuery === "sm"));
        },
        enumerable: true,
        configurable: true
    });
    CoreComponent.prototype.isHomePage = function () {
        return this.router.url === "/" ? !this.currentUser.isAuthenticated() : false;
    };
    CoreComponent.prototype.logout = function () {
        var _this = this;
        this.authService.logout();
        this.authService.init().subscribe(function () {
            _this.router.navigateByUrl("/");
        });
    };
    CoreComponent.prototype.search = function () {
        this.router.navigate(["app/search", { searchKey: this.searchKey }]);
    };
    CoreComponent.prototype.ngOnDestroy = function () {
        for (var i = 0; i < this.subscriptions.length; i++) {
            this.subscriptions[i].unsubscribe();
        }
    };
    CoreComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Title
        // https://toddmotto.com/dynamic-page-titles-angular-2-router-events
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.activatedRoute; }), map(function (route) {
            // Login return url: If the user is not logged in and not on Login/Register pages, then set "login return url"
            if (!_this.authService.currentUser.isAuthenticated()
                && _this.activatedRoute.snapshot.firstChild.routeConfig.path !== "app/account/login"
                && _this.activatedRoute.snapshot.firstChild.routeConfig.path !== "app/account/register") {
                _this.authService.loginReturnUrl = _this.router.url;
            }
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }), filter(function (route) { return route.outlet === "primary"; }), mergeMap(function (route) { return route.data; }))
            .subscribe(function (data) {
            if (data.title) {
                _this.titleService.setTitle("Backbone - " + data.title);
            }
        });
        // Notifications
        var notificationSubscription = this.notificationService.notification.subscribe(function (message) {
            _this.matSnackBar.open(message, "", { duration: 2000 });
        });
        this.subscriptions.push(notificationSubscription);
        // Current user changed subscription
        var currentUserChangedSubscription = this.authService.currentUserChanged.subscribe(function (currentUser) {
            _this.currentUser = currentUser;
        });
        this.subscriptions.push(currentUserChangedSubscription);
        // Media queries
        var mediaSubscription = this.media.subscribe(function (change) {
            _this.mediaQuery = change.mqAlias;
        });
        this.subscriptions.push(mediaSubscription);
    };
    return CoreComponent;
}());
export { CoreComponent };
