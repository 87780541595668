<div class="m-16">
  <h1>Getting Started</h1>
  <mat-divider></mat-divider>
</div>

<p>
  1. Create a new project with 'Todo App' template
</p>

<div>
  <button mat-stroked-button (click)="createProject()" type="button" color="green" [disabled]="project">
    Create a new project
  </button>
</div>

<div *ngIf="exampleCode" class="g-mb-250">

  <p>
    2. Copy the following HTML block:
  </p>

  <div class="code-example-block">
    <header>index.html</header>
    <div class="content">
      <pre>
        <button (click)="copy()" class="material-icons" title="Copy code snippet" aria-label="Copy code snippet index.html">
          <span class="pr-16" aria-hidden="true">content_copy</span>
        </button>
        <code id="code-block">{{ exampleCode }}</code>
      </pre>
    </div>
  </div>

  <p>
    3. Navigate to <a href="https://codepen.io/pen" target="_blank">Codepen</a> and paste the copied block into HTML section
  </p>

  <p>
    4. Voila! You created your first application on Backbone.<br />
    When you add, remove or 'complete' items, your changes will be saved in Backbone.
  </p>

  <p>
    If you wish, you can continue playing around by updating your <a routerLink="/projects/{{ project.Id }}/edit">Todo App</a>
  </p>

</div>
