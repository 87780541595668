<div class="m-16">
  <h1>Reset Password</h1>

  <mat-card>
    <div *ngIf="viewMode === 'initial'">
      <form #resetPasswordRequestForm="ngForm" autocomplete="off">
        <div class="g-mt-20 m-16" [ngClass]="{'has-error': Email.dirty && Email.invalid}">
          <mat-form-field>
            <input matInput id="Email" name="Email" type="text" placeholder="Email" [(ngModel)]="requestBindingModel.Email" #Email="ngModel" required />
          </mat-form-field>
        </div>
        <div class="g-mt-20 m-16">
          <button mat-flat-button (click)="resetPasswordRequest()" [disabled]="resetPasswordRequestForm.form.invalid || isBusy">
            Submit
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="viewMode === 'sent'">
      <p>
        Password reset email has been sent to your address.<br />
        Please use the link in the email to reset your password.
      </p>
    </div>
    <div *ngIf="viewMode === 'received'">
      <form #resetPasswordForm="ngForm">
        <div class="g-mt-20 m-16" [ngClass]="{'has-error': NewPassword.dirty && NewPassword.invalid}">
          <mat-form-field>
            <input matInput id="NewPassword" name="NewPassword" type="password" placeholder="New password" [(ngModel)]="bindingModel.NewPassword"
              #NewPassword="ngModel" required />
          </mat-form-field>
        </div>
        <div class="g-mt-20 m-16" [ngClass]="{'has-error': ConfirmPassword.dirty && ConfirmPassword.invalid}">
          <mat-form-field>
            <input matInput id="ConfirmPassword" name="ConfirmPassword" type="password" placeholder="Confirm new password"
              [(ngModel)]="bindingModel.ConfirmPassword" #ConfirmPassword="ngModel" required />
          </mat-form-field>
        </div>
        <div class="g-mt-20 m-16">
          <button mat-flat-button (click)="resetPassword()" [disabled]="resetPasswordForm.form.invalid || isBusy">
            Submit
          </button>
        </div>
      </form>
    </div>
  </mat-card>
</div>
