import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService, NotificationService } from "@forcrowd/backbone-client-core";
import { timer as observableTimer } from "rxjs";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(activatedRoute, authService, notificationService, router) {
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.notificationService = notificationService;
        this.router = router;
        this.password = "";
        this.rememberMe = true;
    }
    Object.defineProperty(LoginComponent.prototype, "isBusy", {
        get: function () {
            return this.authService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.login = function () {
        var _this = this;
        if (this.username !== "" && this.password !== "") {
            this.authService.login(this.username, this.password, this.rememberMe)
                .subscribe(function () {
                _this.notificationService.notification.next("You have been logged in!");
                // Get return url, reset loginReturnUrl and navigate
                var returnUrl = _this.authService.loginReturnUrl || "";
                _this.authService.loginReturnUrl = "";
                _this.router.navigateByUrl(returnUrl);
            });
        }
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Todo This timer silliness is necessary probably cos of this issue: https://github.com/angular/angular/issues/15634
        observableTimer(0).subscribe(function () {
            // Error
            var error = _this.activatedRoute.snapshot.params["error"];
            if (error) {
                _this.notificationService.notification.next(error);
                return;
            }
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
