import { OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { NotificationService } from "@forcrowd/backbone-client-core";
import { AdminService } from "./admin.service";
import { finalize, mergeMap, map } from "rxjs/operators";
var AdminOverviewComponent = /** @class */ (function () {
    function AdminOverviewComponent(adminService, notificationService) {
        this.adminService = adminService;
        this.notificationService = notificationService;
        this.projectDataSource = new MatTableDataSource([]);
        this.userDataSource = new MatTableDataSource([]);
        // Displayed Columns
        this.userDisplayedColumns = ["username", "projects", "confirm", "date"];
        this.projectDisplayedColumns = ["name", "user", "origin", "date", "functions"];
        this.projectCount = 0;
        this.userCount = 0;
        this.hasResult = false;
        //for Project statistics
        this.projects = [];
        this.todaysProject = [];
        this.lastMonthProjects = [];
        this.lastWeekProjects = [];
        this.projectsTitle = null;
        //for User statistics
        this.users = null;
        this.lastMonthUsers = [];
        this.lastWeekUsers = [];
        this.todaysUser = [];
        this.usersTitle = null;
        // Date
        this.date = new Date();
        this.weekFirstDay = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 7).toISOString().split('T')[0]; //Last week first day
        this.weekLastDay = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()).toISOString().split('T')[0]; // Last week last day
        this.monthFirstDay = new Date(this.date.getFullYear(), this.date.getMonth() - 2, 1).toISOString().split('T')[0];
        this.monthLastDay = new Date(this.date.getFullYear(), this.date.getMonth() - 1, 0).toISOString().split('T')[0];
        this.today = this.date.toISOString().split('T')[0];
    }
    Object.defineProperty(AdminOverviewComponent.prototype, "currentUser", {
        get: function () {
            return this.adminService.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    AdminOverviewComponent.prototype.formatDate = function (v) {
        return v.toISOString().split('T')[0];
    };
    AdminOverviewComponent.prototype.setProjectDataSource = function (data, title) {
        this.projectDataSource.data = data;
        this.projectsTitle = title;
        this.notificationService.notification.next("Please look at the project table");
    };
    AdminOverviewComponent.prototype.setUserDataSource = function (data, title) {
        this.userDataSource.data = data;
        this.usersTitle = title;
        this.notificationService.notification.next("Please look at the user table");
    };
    AdminOverviewComponent.prototype.getTodaysProject = function () {
        var _this = this;
        this.projects.forEach(function (e) {
            var createdOn = _this.formatDate(e.CreatedOn);
            if (createdOn === _this.today)
                _this.todaysProject.push(e);
        });
    };
    AdminOverviewComponent.prototype.getLastMonthProjects = function () {
        var _this = this;
        this.projects.forEach(function (e) {
            var createdOn = _this.formatDate(e.CreatedOn);
            if (createdOn >= _this.monthFirstDay && createdOn <= _this.monthLastDay)
                _this.lastMonthProjects.push(e);
        });
    };
    AdminOverviewComponent.prototype.getLastWeekProjects = function () {
        var _this = this;
        this.projects.forEach(function (e) {
            var createdOn = _this.formatDate(e.CreatedOn);
            if (createdOn >= _this.weekFirstDay && createdOn <= _this.weekLastDay)
                _this.lastWeekProjects.push(e);
        });
    };
    AdminOverviewComponent.prototype.getTodayUsers = function () {
        var _this = this;
        this.users.forEach(function (e) {
            var createdOn = _this.formatDate(e.CreatedOn);
            if (createdOn === _this.today)
                _this.todaysUser.push(e);
        });
    };
    AdminOverviewComponent.prototype.getLastMonthUsers = function () {
        var _this = this;
        this.users.forEach(function (e) {
            var createdOn = _this.formatDate(e.CreatedOn);
            if (createdOn >= _this.monthFirstDay && createdOn <= _this.monthLastDay)
                _this.lastMonthUsers.push(e);
        });
    };
    AdminOverviewComponent.prototype.getLastWeekUsers = function () {
        var _this = this;
        this.users.forEach(function (e) {
            var createdOn = _this.formatDate(e.CreatedOn);
            if (createdOn >= _this.weekFirstDay && createdOn <= _this.weekLastDay)
                _this.lastWeekUsers.push(e);
        });
    };
    AdminOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.adminService.getProject().pipe(finalize(function () {
            _this.projectDataSource.data = _this.projects.slice(0, 5);
            _this.getTodaysProject();
            _this.getLastMonthProjects();
            _this.getLastWeekProjects();
            _this.hasResult = true;
        }))
            .subscribe(function (response) {
            _this.projects = response.results;
            _this.projectCount = response.count;
        });
        this.adminService.getUser().pipe(finalize(function () {
            _this.userDataSource.data = _this.users.slice(0, 5);
            _this.getTodayUsers();
            _this.getLastWeekUsers();
            _this.getLastMonthUsers();
        }))
            .subscribe(function (response) {
            _this.users = response.results;
            _this.userCount = response.count;
        });
    };
    AdminOverviewComponent.prototype.updateComputedFields = function (project) {
        var _this = this;
        this.adminService.updateComputedFields(project).pipe(mergeMap(function () {
            return _this.getProjectSet(true);
        })).subscribe();
    };
    AdminOverviewComponent.prototype.getProjectSet = function (forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        return this.adminService.getProjectSet(false, forceRefresh).pipe(map(function (response) {
            _this.projectDataSource.data = response.results;
        }));
    };
    AdminOverviewComponent.prototype.trackBy = function (index, item) {
        return item.Id;
    };
    return AdminOverviewComponent;
}());
export { AdminOverviewComponent };
