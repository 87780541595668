/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./getting-started.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@angular/material/divider/typings/index.ngfactory";
import * as i5 from "@angular/material/divider";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./getting-started.component";
import * as i12 from "@forcrowd/backbone-client-core";
var styles_GettingStartedComponent = [i0.styles];
var RenderType_GettingStartedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GettingStartedComponent, data: {} });
export { RenderType_GettingStartedComponent as RenderType_GettingStartedComponent };
function View_GettingStartedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "div", [["class", "g-mb-250"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 2. Copy the following HTML block: "])), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "code-example-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "header", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["index.html"])), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["        "])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["aria-label", "Copy code snippet index.html"], ["class", "material-icons"], ["title", "Copy code snippet"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["aria-hidden", "true"], ["class", "pr-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["content_copy"])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "code", [["id", "code-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 3. Navigate to "])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "a", [["href", "https://codepen.io/pen"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Codepen"])), (_l()(), i1.ɵted(-1, null, [" and paste the copied block into HTML section "])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 4. Voila! You created your first application on Backbone."])), (_l()(), i1.ɵeld(25, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" When you add, remove or 'complete' items, your changes will be saved in Backbone. "])), (_l()(), i1.ɵeld(27, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" If you wish, you can continue playing around by updating your "])), (_l()(), i1.ɵeld(29, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(30, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Todo App"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "/projects/", _co.project.Id, "/edit"); _ck(_v, 30, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.exampleCode; _ck(_v, 16, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 30).target; var currVal_2 = i1.ɵnov(_v, 30).href; _ck(_v, 29, 0, currVal_1, currVal_2); }); }
export function View_GettingStartedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "m-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Getting Started"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-divider", [["class", "mat-divider"], ["role", "separator"]], [[1, "aria-orientation", 0], [2, "mat-divider-vertical", null], [2, "mat-divider-horizontal", null], [2, "mat-divider-inset", null]], null, null, i4.View_MatDivider_0, i4.RenderType_MatDivider)), i1.ɵdid(4, 49152, null, 0, i5.MatDivider, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 1. Create a new project with 'Todo App' template\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["color", "green"], ["mat-stroked-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createProject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" Create a new project "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GettingStartedComponent_1)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.project; var currVal_7 = "green"; _ck(_v, 9, 0, currVal_6, currVal_7); var currVal_8 = _co.exampleCode; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).vertical ? "vertical" : "horizontal"); var currVal_1 = i1.ɵnov(_v, 4).vertical; var currVal_2 = !i1.ɵnov(_v, 4).vertical; var currVal_3 = i1.ɵnov(_v, 4).inset; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 9).disabled || null); var currVal_5 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_4, currVal_5); }); }
export function View_GettingStartedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_GettingStartedComponent_0, RenderType_GettingStartedComponent)), i1.ɵdid(1, 114688, null, 0, i11.GettingStartedComponent, [i12.AuthService, i12.ProjectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GettingStartedComponentNgFactory = i1.ɵccf("ng-component", i11.GettingStartedComponent, View_GettingStartedComponent_Host_0, {}, {}, []);
export { GettingStartedComponentNgFactory as GettingStartedComponentNgFactory };
