import { moveItemInArray } from "@angular/cdk/drag-drop";
import { SelectionModel } from "@angular/cdk/collections";
import { EventEmitter, OnInit } from "@angular/core";
import { MatDialog, MatTable } from "@angular/material";
import { ElementFieldDataType, Project, ProjectService } from "@forcrowd/backbone-client-core";
import { finalize } from "rxjs/operators";
import { RemoveConfirmComponent } from "./remove-confirm.component";
var ElementFieldManagerComponent = /** @class */ (function () {
    function ElementFieldManagerComponent(projectService, dialog) {
        this.projectService = projectService;
        this.dialog = dialog;
        this.project = null;
        this.projectOwner = null;
        this.isEditingChanged = new EventEmitter();
        this.selection = new SelectionModel(true, []);
        this.elementFieldDisplayedColumns = ["select", "element", "name", "dataType", "createdOn"];
        this.elementFieldDataType = ElementFieldDataType;
        this.selectedElementList = [];
        this.fields = {
            selectedElementField: null,
            elementFilter: null,
        };
    }
    Object.defineProperty(ElementFieldManagerComponent.prototype, "selectedElementField", {
        get: function () {
            return this.fields.selectedElementField;
        },
        set: function (value) {
            var _this = this;
            if (this.fields.selectedElementField !== value) {
                this.fields.selectedElementField = value;
                // Prepare selected element list
                if (this.selectedElementField) {
                    this.selectedElementList = this.project.ElementSet
                        .filter(function (element) { return element !== _this.selectedElementField.Element // Exclude element field's parent element
                        && element.ParentFieldSet.length === 0; }); // and elements that are already selected
                }
                this.isEditingChanged.emit(value ? true : false);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementFieldManagerComponent.prototype, "elementFilter", {
        get: function () {
            return this.fields.elementFilter;
        },
        set: function (value) {
            if (this.fields.elementFilter !== value) {
                this.fields.elementFilter = value;
                if (value) {
                    value.ElementFieldSet.sort(function (a, b) { return a.SortOrder - b.SortOrder; });
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ElementFieldManagerComponent.prototype, "isBusy", {
        get: function () {
            return this.projectService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ElementFieldManagerComponent.prototype.addElementField = function () {
        this.selectedElementField = this.projectService.createElementField({
            Element: this.elementFilter,
            Name: "New field",
            DataType: ElementFieldDataType.String
        });
    };
    ElementFieldManagerComponent.prototype.cancelElementField = function () {
        var elementField = this.selectedElementField;
        this.selectedElementField = null;
        this.projectService.rejectChangesElementField(elementField);
    };
    ElementFieldManagerComponent.prototype.editElementField = function (elementField) {
        this.selectedElementField = elementField;
    };
    ElementFieldManagerComponent.prototype.ngOnInit = function () {
        this.elementFilter = this.project.ElementSet[0];
        if (!this.projectOwner)
            this.elementFieldDisplayedColumns.splice(0, 1);
    };
    ElementFieldManagerComponent.prototype.onListDrop = function ($event) {
        if (!this.elementFilter) {
            return;
        }
        // Update data & render
        var prevIndex = this.elementFilter.ElementFieldSet.findIndex(function (d) { return d === $event.item.data; });
        moveItemInArray(this.elementFilter.ElementFieldSet, prevIndex, $event.currentIndex);
        this.matTable.renderRows();
        // Update elements' SortOrder property
        this.elementFilter.ElementFieldSet.map(function (e, i) {
            if (e.SortOrder !== i) {
                e.SortOrder = i;
            }
        });
        // Save
        this.projectService.saveChanges().subscribe();
    };
    ElementFieldManagerComponent.prototype.removeElementField = function () {
        var _this = this;
        var dialogRef = this.dialog.open(RemoveConfirmComponent);
        dialogRef.afterClosed().subscribe(function (confirmed) {
            if (!confirmed)
                return;
            if (_this.selection.selected.length > 0) {
                _this.selection.selected.forEach(function (elementField) {
                    _this.projectService.removeElementField(elementField);
                });
                _this.projectService.saveChanges().pipe(finalize(function () {
                    _this.matTable.renderRows();
                    _this.selection.clear();
                })).subscribe();
            }
        });
    };
    ElementFieldManagerComponent.prototype.saveElementField = function () {
        var _this = this;
        this.selectedElementField.SortOrder = this.selectedElementField.Element.ElementFieldSet.length;
        this.projectService.saveElementField(this.selectedElementField)
            .subscribe(function () {
            _this.selectedElementField = null;
        });
    };
    ElementFieldManagerComponent.prototype.submitDisabled = function () {
        var hasValidationErrors = this.selectedElementField.entityAspect.getValidationErrors().length > 0
            || (this.selectedElementField.DataType === ElementFieldDataType.Element && !this.selectedElementField.SelectedElement);
        return this.isBusy || hasValidationErrors;
    };
    ElementFieldManagerComponent.prototype.isAllSelected = function () {
        if (!this.elementFilter)
            return false;
        var numSelected = this.selection.selected.length;
        var numRows = this.elementFilter.ElementFieldSet.length;
        return numSelected === numRows;
    };
    ElementFieldManagerComponent.prototype.masterToggle = function () {
        var _this = this;
        if (!this.elementFilter)
            return;
        this.isAllSelected() ?
            this.selection.clear() :
            this.elementFilter.ElementFieldSet.forEach(function (row) { return _this.selection.select(row); });
    };
    ElementFieldManagerComponent.prototype.trackBy = function (index, elementField) {
        return elementField.Id;
    };
    return ElementFieldManagerComponent;
}());
export { ElementFieldManagerComponent };
