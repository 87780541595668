import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService, NotificationService, getUniqueUserName, stripInvalidChars } from "@forcrowd/backbone-client-core";
import { settings } from "../../settings/settings";
import { AccountService } from "./account.service";
var ChangeUserNameComponent = /** @class */ (function () {
    function ChangeUserNameComponent(accountService, authService, notificationService, router) {
        this.accountService = accountService;
        this.authService = authService;
        this.notificationService = notificationService;
        this.router = router;
        this.bindingModel = {
            get UserName() {
                return this.fields.userName;
            },
            set UserName(value) {
                this.fields.userName = stripInvalidChars(value);
            },
            fields: {
                userName: ""
            }
        };
    }
    Object.defineProperty(ChangeUserNameComponent.prototype, "isBusy", {
        get: function () {
            return this.accountService.isBusy || this.authService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    ChangeUserNameComponent.prototype.cancel = function () {
        // To be able to pass CanDeactivate
        this.bindingModel.UserName = this.authService.currentUser.UserName;
        // Get return url, reset loginReturnUrl and navigate
        var returnUrl = this.authService.loginReturnUrl || "/app/account";
        this.authService.loginReturnUrl = "";
        this.router.navigateByUrl(returnUrl);
    };
    ChangeUserNameComponent.prototype.canDeactivate = function () {
        if (this.bindingModel.UserName === this.authService.currentUser.UserName) {
            return true;
        }
        return confirm("Discard changes?");
    };
    ChangeUserNameComponent.prototype.changeUserName = function () {
        var _this = this;
        this.accountService.changeUserName(this.bindingModel)
            .subscribe(function () {
            _this.notificationService.notification.next("Your username has been changed!");
            // Get return url, reset loginReturnUrl and navigate
            var returnUrl = _this.authService.loginReturnUrl || "/app/account";
            _this.authService.loginReturnUrl = "";
            _this.router.navigateByUrl(returnUrl);
        });
    };
    ChangeUserNameComponent.prototype.ngOnInit = function () {
        // User name
        this.bindingModel.UserName = this.authService.currentUser.UserName;
        // Generate test data if localhost
        if (settings.environment === "Development") {
            this.bindingModel.UserName = getUniqueUserName();
        }
    };
    ChangeUserNameComponent.prototype.submitDisabled = function () {
        return this.bindingModel.UserName === this.authService.currentUser.UserName || this.isBusy;
    };
    return ChangeUserNameComponent;
}());
export { ChangeUserNameComponent };
