import * as tslib_1 from "tslib";
import { ElementFieldDataType, ProjectService } from "@forcrowd/backbone-client-core";
import { settings } from "../../settings/settings";
var AppProjectService = /** @class */ (function (_super) {
    tslib_1.__extends(AppProjectService, _super);
    function AppProjectService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppProjectService.prototype.createProjectTodo = function () {
        // Project
        var project = _super.prototype.createProjectEmpty.call(this);
        project.Name = "Todo App";
        project.Origin = settings.todoAppOrigin;
        // Element
        var element = _super.prototype.createElement.call(this, {
            Project: project,
            Name: "Main"
        });
        // Field
        var elementField = _super.prototype.createElementField.call(this, {
            Element: element,
            Name: "Completed",
            DataType: ElementFieldDataType.Decimal,
            UseFixedValue: false,
            RatingEnabled: false,
            SortOrder: 1
        });
        // Item 1
        var elementItem1 = _super.prototype.createElementItem.call(this, {
            Element: element,
            Name: "Create a project on Backbone"
        });
        // Cell 1
        var cell1 = _super.prototype.createElementCell.call(this, {
            ElementField: elementField,
            ElementItem: elementItem1
        });
        // User cell 1
        _super.prototype.createUserElementCell.call(this, cell1, 1);
        // Item 2
        var elementItem2 = _super.prototype.createElementItem.call(this, {
            Element: element,
            Name: "Read 'The Little Prince' book"
        });
        // Cell 2
        var cell2 = _super.prototype.createElementCell.call(this, {
            ElementField: elementField,
            ElementItem: elementItem2
        });
        // User cell 2
        _super.prototype.createUserElementCell.call(this, cell2, 0);
        // Item 3
        var elementItem3 = _super.prototype.createElementItem.call(this, {
            Element: element,
            Name: "Watch 'Shawshank Redemption' movie"
        });
        // Cell 3
        var cell3 = _super.prototype.createElementCell.call(this, {
            ElementField: elementField,
            ElementItem: elementItem3
        });
        // User cell 3
        _super.prototype.createUserElementCell.call(this, cell3, 0);
        // Item 4
        var elementItem4 = _super.prototype.createElementItem.call(this, {
            Element: element,
            Name: "Visit 'Niagara Falls'"
        });
        // Cell 4
        var cell4 = _super.prototype.createElementCell.call(this, {
            ElementField: elementField,
            ElementItem: elementItem4
        });
        // User cell 4
        _super.prototype.createUserElementCell.call(this, cell4, 0);
        return project;
    };
    return AppProjectService;
}(ProjectService));
export { AppProjectService };
