/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./account-delete-confirm.component";
var styles_AccountRemoveConfirmComponent = [];
var RenderType_AccountRemoveConfirmComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccountRemoveConfirmComponent, data: {} });
export { RenderType_AccountRemoveConfirmComponent as RenderType_AccountRemoveConfirmComponent };
export function View_AccountRemoveConfirmComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h3", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(-1, null, ["Confirmation"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵted(-1, null, [" Are you sure do you want to delete your account?\n"])), (_l()(), i0.ɵeld(6, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["color", "primary"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).dialogRef.close(i0.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(9, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(10, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Delete"])), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["class", "mat-m02"], ["color", "warn"], ["mat-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).dialogRef.close(i0.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(14, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Cancel"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = "primary"; _ck(_v, 9, 0, currVal_4); var currVal_5 = true; _ck(_v, 10, 0, currVal_5); var currVal_9 = "warn"; _ck(_v, 13, 0, currVal_9); var currVal_10 = false; _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = (i0.ɵnov(_v, 9).disabled || null); var currVal_2 = (i0.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_3 = i0.ɵnov(_v, 10).ariaLabel; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = (i0.ɵnov(_v, 13).disabled || null); var currVal_7 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_8 = i0.ɵnov(_v, 14).ariaLabel; _ck(_v, 12, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_AccountRemoveConfirmComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "account-delete-confirm", [], null, null, null, View_AccountRemoveConfirmComponent_0, RenderType_AccountRemoveConfirmComponent)), i0.ɵdid(1, 49152, null, 0, i7.AccountRemoveConfirmComponent, [], null, null)], null, null); }
var AccountRemoveConfirmComponentNgFactory = i0.ɵccf("account-delete-confirm", i7.AccountRemoveConfirmComponent, View_AccountRemoveConfirmComponent_Host_0, {}, {}, []);
export { AccountRemoveConfirmComponentNgFactory as AccountRemoveConfirmComponentNgFactory };
