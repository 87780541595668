<div *ngIf="project" class="m-16">

  <!-- Element cell list -->
  <div *ngIf="!selectedElementCell">
    <div class="cellFilterContainer g-mt-20">
      <mat-form-field>
        <mat-select placeholder="Select an element" [(value)]="elementFilter">
          <mat-option *ngFor="let element of project.ElementSet" [value]="element">{{ element.Name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select placeholder="Select a field" [(value)]="elementFieldFilter">
          <mat-option *ngFor="let elementField of elementFilter?.ElementFieldSet" [value]="elementField">{{
            elementField.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-card class="mat-card-std g-mb-250 g-mt-20">
      <table mat-table [dataSource]="elementCellDataSource" [trackBy]="trackBy" class="g-mt-20">

        <ng-container matColumnDef="elementItem">
          <th mat-header-cell *matHeaderCellDef> Item </th>
          <td mat-cell *matCellDef="let cell">
            <a *ngIf="projectOwner" (click)="editElementCell(cell)">{{ cell.ElementItem.Name }}</a>
            <span *ngIf="!projectOwner"> {{ cell.ElementItem.Name }} </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let cell">
            <span *ngIf="cell.ElementField.DataType === elementFieldDataType.String">
              {{ cell.StringValue }}
            </span>
            <span *ngIf="cell.ElementField.DataType === elementFieldDataType.Decimal">
              {{ cell.UserElementCellSet[0] ? cell.UserElementCellSet[0].DecimalValue : "N/A" }}
            </span>
            <span *ngIf="cell.ElementField.DataType === elementFieldDataType.Element">
              {{ cell.SelectedElementItem ? cell.SelectedElementItem.Name : "N/A" }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef> Created On</th>
          <td mat-cell *matCellDef="let cell"> {{ cell.CreatedOn | amTimeAgo }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="elementCellDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: elementCellDisplayedColumns;"></tr>
      </table>
    </mat-card>
  </div>

  <!-- Element cell edit -->
  <div *ngIf="selectedElementCell">
    <mat-card class="mat-card-std g-mb-250 g-mt-20">
      <form #elementCellForm="ngForm" autocomplete="off">
        <div class="g-mt-40 m-16">
          <div class="g-mt-20">
            <label>Element</label>
            <p>{{ selectedElementCell.ElementField.Element.Name }}</p>
          </div>
          <div class="g-mt-20">
            <label>Element Field</label>
            <p>{{ selectedElementCell.ElementField.Name }}</p>
          </div>
          <div class="g-mt-20">
            <label>Element Item</label>
            <p>{{ selectedElementCell.ElementItem.Name }}</p>
          </div>
          <div *ngIf="selectedElementCell.ElementField.DataType === elementFieldDataType.String" [ngClass]="{ 'has-error': selectedElementCell.entityAspect.getValidationErrors('StringValue').length > 0 }"
            class="g-mt-20">
            <mat-form-field>
              <input matInput placeholder="Value" id="StringValue" name="StringValue" [(ngModel)]="selectedElementCell.StringValue" />
            </mat-form-field>
          </div>
          <div *ngIf="selectedElementCell.ElementField.DataType === elementFieldDataType.Decimal" [ngClass]="{ 'has-error': selectedElementCell.UserElementCellSet[0] && selectedElementCell.UserElementCellSet[0].entityAspect.getValidationErrors('DecimalValue').length > 0 }"
            class="g-mt-20">
            <mat-form-field>
              <input matInput placeholder="Value" id="DecimalValue" name="DecimalValue" [(ngModel)]="selectedElementDecimalValue" />
            </mat-form-field>
          </div>
          <div *ngIf="selectedElementCell.ElementField.DataType === elementFieldDataType.Element" [ngClass]="{ 'has-error': selectedElementCell.entityAspect.getValidationErrors('SelectedItem').length > 0 }"
            class="g-mt-20">
            <mat-form-field>
              <mat-select id="SelectedElementItemId" name="SelectedElementItemId" placeholder="Selected Item" [(value)]="selectedElementCell.SelectedElementItem">
                <mat-option *ngFor="let elementItem of selectedElementCell.ElementField.SelectedElement.ElementItemSet"
                  [value]="elementItem">{{ elementItem.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="g-mt-20">
            <button mat-flat-button (click)="saveElementCell()" [disabled]="submitDisabled()" class="mat-m02">
              Save cell
            </button>
            <button mat-flat-button (click)="cancelElementCell()" [disabled]="isBusy" color="warn" class="mat-m02">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>

</div>
