<div class="m-16">
  <h1>Are you lost?</h1>

  <mat-card>
    <div class="g-mt-20">
      <p>
        The page you were looking for appears to have been moved, deleted or does not exist.<br />
        Would you like to go <a routerLink="/">back</a>?
      </p>
    </div>
  </mat-card>
</div>
