import { OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "@forcrowd/backbone-client-core";
import { AccountService } from "./account.service";
var ResetPasswordComponent = /** @class */ (function () {
    function ResetPasswordComponent(activatedRoute, accountService, notificationService, router) {
        this.activatedRoute = activatedRoute;
        this.accountService = accountService;
        this.notificationService = notificationService;
        this.router = router;
        this.bindingModel = {
            Email: "",
            Token: "",
            NewPassword: "",
            ConfirmPassword: ""
        };
        this.requestBindingModel = {
            Email: ""
        };
        this.viewMode = "initial";
    }
    Object.defineProperty(ResetPasswordComponent.prototype, "isBusy", {
        get: function () {
            return this.accountService.isBusy;
        },
        enumerable: true,
        configurable: true
    });
    // Todo cancel?
    ResetPasswordComponent.prototype.isSaveDisabled = function () {
        return this.isBusy;
    };
    ResetPasswordComponent.prototype.ngOnInit = function () {
        var email = this.activatedRoute.snapshot.params["email"];
        var token = this.activatedRoute.snapshot.params["token"];
        this.bindingModel.Email = email;
        this.bindingModel.Token = token;
        this.viewMode = typeof email === "undefined" ||
            typeof token === "undefined"
            ? "initial"
            : "received"; // initial | sent | received
    };
    ResetPasswordComponent.prototype.resetPassword = function () {
        var _this = this;
        this.accountService.resetPassword(this.bindingModel)
            .subscribe(function () {
            _this.notificationService.notification.next("Your password has been reset!");
            _this.router.navigate(["/app/account/login"]);
        });
    };
    ResetPasswordComponent.prototype.resetPasswordRequest = function () {
        var _this = this;
        this.accountService.resetPasswordRequest(this.requestBindingModel)
            .subscribe(function () {
            _this.viewMode = "sent";
        });
    };
    return ResetPasswordComponent;
}());
export { ResetPasswordComponent };
